import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import PageHeadingTitle from "@components/pageHeadingTitle"

const SawsPage = ({ data }) => {
  /*const page = data && data.page*/

  return (
    <Layout>
      {/*<SEO title={page.title} />*/}
      <SEO
        title="Precision Industrial Saws | Fabricating Equipment Sales"
        description="Discover Hyd-Mech saws, from compact double-miter to heavy-duty gantry and plate saws. Built for precision and durability, ideal for diverse cutting needs."
      />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle h1={"Products"} h2={"Saws"} />
        </div>
      </div>

      <div className="page-body">
        <div className="container">
          {/*<BlockContent
              blocks={page._rawBody || []}
              serializers={serializers}
            />*/}
          <div className="row">
            {/*<div className="col-md-3">
              <div className="products-sidebar">
                <div className="mb-4">
                  <span className="font-weight-bold text-uppercase text-primary d-inline-block border-bottom">
                    Products
                  </span>
                  <h1 className="display-4">Saws</h1>
                </div>
                <h4 className="text-uppercase font-weight-bolder">Brands</h4>
                <ul className="list-unstyled">
                  <li>HYDMECH</li>
                </ul>
              </div>
            </div>*/}
            <div className="col-lg-6">
              <div className="text-center mb-5">
                <img
                  className="img-fluid"
                  src={require("@images/products/saws/hyd-mech_logo.jpg")}
                  alt="HYDMECH"
                />
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_DM1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_DM2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Double-miter / PH Machines
                    </h2>
                    <p>
                      The DM-Series might be the smallest saws in the Hyd·Mech
                      line-up, but there is nothing lightweight about these
                      compact double-miter saws. Built with the same rugged and
                      tough tradition as the bigger machines, our new DM saws
                      offer all the Rock Solid performance benefits you’ve come
                      to expect. The line-up features manual machines with
                      mitering capability up to 60º, left and right.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>DM-10 / PH101/ DM-1318P/ PH211/ SM10CNC / PH261</p>
                  </div>
                </div>
                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_S1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_S2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Scissors
                    </h2>
                    <p>
                      Our S-Series Saws were the first-ever saws in the industry
                      to incorporate swing-head capability. These versatile,
                      scissors-style machines are designed for multiple
                      light/medium duty applications and are ideal for use in
                      tight working spaces. The line-up features manual,
                      semi-automatic and fully-automatic models with mitering
                      capability from 90 to 30 degrees.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>
                      S-20 Series III / S-23A / New S-20A S-23P / S-20P Series
                      III / S-23
                    </p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_H1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_H2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Horizontal / Guillotine
                    </h2>
                    <p>
                      Our H-Series saws are highly-versatile, guillotine-style
                      machines designed to provide exceptional straight cutting
                      capability in multi-shift, high production environments.
                      The line-up features semi-automatic and fully-automatic
                      models with cutting capacity of up to 80".
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>
                      H-10A / H-22A / H-32A / H-14A / H-22A-120 H-40/40 / H-18A
                      H-26/42 / H-40/60 / H-18A-120 / H-28A / H-40/80
                    </p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_M1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_M2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Heavier-duty Scissors
                    </h2>
                    <p>
                      Our M-Series Saws are heavy-duty scissors-style machines
                      designed to provide exceptional miter cutting capability
                      with an optimum range of between 90 and 30 degrees. The
                      line-up features semi-automatic and fully-automatic models
                      with cutting capacity of up to 30".
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>M-16A / M-20A / M-20P / M-20A-120</p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_V1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_V2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Vertical Tilt-Head
                    </h2>
                    <p>
                      Our V-Series Saws are heavy-duty vertical tilt-head frame
                      machines with the flexibility to miter up to 45º left or
                      right. These machines are designed for round-the-clock
                      operation. The line-up features semi-automatic and
                      fully-automatic models with cutting capacity of up to 30".
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>VW-18 II / V25-APC / V-18 / V18-APC / V-25</p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_VCS1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_VCS2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Vertical Contour
                    </h2>
                    <p>
                      Hyd-Mech VCS-Series are heavy-duty vertical contour-style
                      saws. Designed with the flexibility to cut tight radius
                      sections or larger cut-off sections, VCS saws are multiple
                      purpose saws which can not only cut steel, but also,
                      aluminum, wood, plastics, glass and rubber.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>VCS-20 / VCS-20VSD / VCS-36VFD / VCS-36VSD</p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_cold_saws1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_cold_saws2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Cold Saws
                    </h2>
                    <p>
                      Hyd-Mech now offers a complete line of quality Circular
                      (Cold) Saw solutions. Cutting with a rigid HSS circular
                      blade, Cold Sawing provides, clean, straight and accurate
                      cutting of most metal applications, both Ferrous and
                      Non-Ferrous. The line-up features manual, automatic,
                      semi-automatic models with mitering capacity from 90º, 45º
                      right or left, 60º left, and 45º tilted head.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>
                      P225 / C350-2AV / CNF400CNC / PNF350CNC / P250 / C370CNC /
                      PNF350A / P350 / C370-2SI / PNF350-2S / C350-2S /
                      C350-2CNC / PNF350-2AV
                    </p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_ELH1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_ELH2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Extra Large Horizontal
                    </h2>
                    <p>
                      The extra large horizontal band saws are capable of fast
                      cuts up to 60" square. Cut big bundles, big ingots, heavy
                      solids, large hollows - just about any size or shape of
                      material on our heavy-duty, large production band saws.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>SC Series - Horizontal Dual Column</p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_plate1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_plate2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Plate Saws
                    </h2>
                    <p>
                      The SVT models allow for the cross cutting of plates,
                      sheets, structural steel and blocks. Applications include
                      tool steel, aluminum, stainless steel sheets and plates
                    </p>
                    <p>
                      The SVL models are cost-efficient vertical band saws with
                      a traveling head designed to cut large carbon steel and
                      stainless steel plates, slabs, blocks and ingots.
                    </p>
                    <p>
                      The SVC models are cost-efficient vertical band saws with
                      a traveling head designed to cut large carbon steel and
                      stainless steel plates, slabs, blocks and ingots. It is
                      possible to cut both the length and width of the material
                      by fitting the machine with two rotating (+ 0° - 90°)
                      blade guides.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>
                      SVT Series - Vertical Transversal Plate Saws
                      <br />
                      SVL Series - Vertical Longitudinal Plate Saws
                      <br />
                      SVC Series - Vertical Combined Plate Saws
                    </p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_gantry1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_gantry2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder">
                      Gantry Saws
                    </h2>
                    <p>
                      The large Gantry series combine a modern machine tool
                      design and Soitaab’s extensive experience in the
                      efficient, cost-effective cutting of large work pieces.
                      This is a machine designed to cut large heavy blocks of
                      metal.
                    </p>
                    <p className="text-uppercase text-primary font-weight-bold h6">
                      Models
                    </p>
                    <p>Gantry Series - Gantry Machines</p>
                  </div>
                </div>

                <div className="col-12 mb-5">
                  <div className="p-5 rounded border h-100">
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_material1.jpg")}
                        alt="DM Series"
                      />
                      <img
                        className="img-fluid"
                        src={require("@images/products/saws/hyd_mech_material2.jpg")}
                        alt="DM Series"
                      />
                    </div>
                    <h2 className="text-primary font-weight-bolder h3">
                      Custom Products / Material Handling
                    </h2>
                    <p>
                      Hyd-Mech offers its customers a wide range of innovative
                      solutions for material handling as well as custom designed
                      Rock Solid sawing solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-center mb-5">
                <img
                  className="img-fluid"
                  src={require("@images/products/saws/cosen_logo.jpg")}
                  alt="COSEN Saws"
                />
              </div>
              <div className="col-12 mb-5">
                <div className="p-5 rounded border h-100">
                  <p className="text-muted font-italic text-center lead m-0">
                    Coming Soon
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link className="btn btn-primary" to="/products">
              Back to All Products
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SawsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
  }
`

export default SawsPage
